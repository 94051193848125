var Lazyimage = (function() {
	function Lazyimage() {
		if(window.customElements) {
			this.customElement();
		}else {
			this.fallback();
		}
	}

	Lazyimage.prototype.fallback = function() {
		this.is = 'fallback';

		function d() {
			var lazyimage = $('lazy-image');

			lazyimage.each(function() {
				var img = $(this).find('img');

				if(img.length == 0) {
					var src = $(this).attr('src');
					var alt = $(this).attr('alt');

					$(this).append(`<img src="${src}" alt="${alt}">`);
					$(this).addClass('loaded')
				}
			});
		}

		d();

		setInterval(d, (1000/60));
	}

	Lazyimage.prototype.customElement = function () {
		this.is = 'customElements';

		class LazyImage extends HTMLElement {
			constructor() {
				super();
				this.src = this.getAttribute('src');
				this.alt = this.getAttribute('alt');

				const self = this;
				const observer = new IntersectionObserver((entries) => {
					$(entries).each(function(index, entry) {
						if(entry.isIntersecting) {
							const img = document.createElement('img');

				        	img.src = self.src || '';
				        	img.alt = self.alt || '';

				        	entry.target.appendChild(img);

				        	img.addEventListener('load', function() {
				        		$(entry.target).addClass('loaded');
				        	});

				        	observer.unobserve(entry.target);
						}
					});
				});

				observer.observe(this);
			}
		}

		customElements.define('lazy-image', LazyImage);
	}

	return new Lazyimage();
})();
